.atf {
  width: 100%;
  height: auto;
  background-image: url('../images/bg_pc.png');
  background-size: 100% 95%;
  background-repeat: no-repeat;

  @include mq() {
    background-image: url('../images/bg-ki.png');
  }

  &__container {
    position: relative;
    padding-top: 12%;
    padding-right: 8%;
    padding-bottom: 20%;
    padding-left: 7%;
  }

  &__content {
    margin-bottom: 3.5%;
  }

  &__calendar-image {
    width: 55%;
    display: block;
  }

  &__title {
    width: 55%;
    position: absolute;
    top: 2%;
    right: 4%;
  }

  &__title-image {
    display: block;
  }

  &__sub-title {
    position: absolute;
    top: 25%;
    right: 19%;
    font-family: dnp-shuei-mgothic-std, sans-serif;
    writing-mode: vertical-rl;
    @include fontSizePxToRem(17);
    line-height: 1.5;
  }

  &__date {
    width: 33%;
    position: absolute;
    top: 55.5%;
    right: 10%;
  }

  &__date-image {
    display: block;
  }

  &__benefits{
    width: 53%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2%;
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1%;
  }

  &__amount {
    @include fontSizePxToRem(20.5);
    margin-right: 2%;
  }

  &__tax {
    @include fontSizePxToRem(11.8);
    transform: translateY(-15%);
  }

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 2%;
  }

  &__notes {
    @extend .font-fot-tsukubrdgothic-std--r;
    @include fontSizePxToRem(10);
    line-height: 1.4;
    text-align: center;
  }
}