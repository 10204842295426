.font-dnp-shuei-mgothic-std--b {
  font-family: "YakuHanJP", dnp-shuei-mgothic-std, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.font-fot-tsukubrdgothic-std--r {
  font-family: "YakuHanJP", fot-tsukubrdgothic-std, sans-serif;
  font-style: normal;
  font-weight: 400;
}