* {
  box-sizing: border-box;
}

body {
  @extend .font-dnp-shuei-mgothic-std--b;
  background-color: #ffffff;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.br {
  &--sp {
    display: none;
    @include mq() {
      display: block;
    }
  }
}

a, button {
  transition: all 300ms;
  &:hover {
    opacity: .6;
  }
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.main {
  overflow: hidden;
}

.container {
  max-width: pxToRem(375);
  margin: 0 auto;
}

.bg__arrow {
  width: 100%;
  height: auto;
  border-right: 50vw solid transparent;
  border-left:  50vw solid transparent;
}

.callout-image {
  opacity: 0;
}

picture[data-animation='fade-in'] {
  opacity: 1;
  transition: opacity 1000ms ease-out;
}