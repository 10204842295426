.recommend {

  &__container {
    margin-bottom: -9%;
    padding-top: 3%;
    padding-right: 1%;
    padding-left: 5%;

    @include mq() {
      margin-bottom: -4%;
      padding-top: 2%;
    }
  }

  &__title {
    width: 73%;
    margin: 0 auto;
    margin-bottom: 9%;
  }

  &__title-image {
    display: block;
    transform: translateX(-9%);
  }

  &__point {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--1 {
      margin-bottom: 10%;

      .point {
        &__image-block {
          width: 60%;
          margin-right: 2%;
          background-color: #1f7e30;
        }
      }
    }

    &--2 {
      margin-bottom: 9%;

      .point {
        &__image-block {
          width: 60%;
          margin-right: 4%;
          margin-left: auto;
          background-color: #ffde4d;
        }
      }
    }

    &--3 {
      margin-bottom: 9%;

      .point {
        &__image-block {
          width: 58%;
          margin-right: 2%;
          background-color: #ff6157;
        }

        &__text {
          letter-spacing: pxToRem(-1);
        }
      }
    }

    &--4 {
      margin-bottom: 6.5%;

      .point {
        &__image-block {
          width: 58%;
          margin-right: 5%;
          margin-left: auto;
          background-color: #c8de5d;
        }
      }
    }
  }

  .point {
    &__image {
      display: block;
      transform: translate(0, .3%);
    }

    &__image[data-animation='slide'] {
      transform: translate(-5%, -5%);
      transition: transform 500ms ease-out;
    }

    &__text {
      @include fontSizePxToRem(10.5);
      line-height: 1.7;
    }
  }

  &__price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1%;
  }

  &__amount {
    margin-right: 1%;
    @include fontSizePxToRem(20.5);
  }

  &__tax {
    @include fontSizePxToRem(11.8);
    transform: translateY(-15%);
  }

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
    padding-right: 5%;
    padding-left: 4%;
  }

  &__info {
    margin-top: -1%;
  }

  &__notes {
    @include fontSizePxToRem(10);
    line-height: 1.4;
    text-align: center;
  }

  &__bg-arrow {
    @extend .bg__arrow;
    border-top: pxToRem(65) solid #ffffff;
    background-color: #ffeb8a;
  }
}