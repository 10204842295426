.trial {

  &__container {
    position: relative;
    padding-top: 22%;
    padding-bottom: 5%;
  }

  &__title {
    width: 45%;
    position: absolute;
    top: -4%;
    left: 2%;
  }

  &__title-image {
    display: block;
  }

  &__supatto-san-image {
    display: block;
    width: 30%;
    position: absolute;
    top: -7%;
    right: -3%;

    @include mq() {
      top: -14%;
      right: 4%;
    }
  }

  &__calendar-block {
    width: 89%;
    margin: 0 auto;

    .slick-slide {
      opacity: 1;
      transition: opacity 500ms ease-out;
    }

    .slick-slide:not(.slick-center) {
      opacity: .5;
      transition: opacity 500ms ease-out;
    }
  }

  .calendar {
    &__image {
      display: block;
      transform: translateY(0.5%);
    }
  }

  &__bg-arrow {
    @extend .bg__arrow;
    border-top: pxToRem(60) solid #ffffff;
    background-color: #ffeb8a;
  }
}