.footer {

  &__container {
    position: relative;
    padding-top: 10%;
    padding-right: 3%;
    padding-bottom: 2%;
    padding-left: 5%;

    @include mq() {
      padding-top: 7%;
    }
  }

  &__title {
    width: 32%;
    position: absolute;
    top: 2%;
    left: 8%;
  }

  &__title-image {
    display: block;
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 5%;
  }

  &__profile-image {
    display: block;
    width: 50%;
    padding-right: 16%;
    padding-left: 9%;
  }

  &__links {
    width: 50%;
  }

  &__interview {
    margin-bottom: 5%;
  }

  &__officialsite {
    margin-bottom: 5%;
  }

  .interview,
  .officialsite,
  .twitter {
    &__link {
      display: block;
    }

    &__image {
      display: block;
    }
  }

  &__pagetop {
    display: flex;
    justify-content: center;
    margin-bottom: 15%;
  }

  &__pagetop-link {
    display: block;
    width: 50%;
    animation: floating 4s ease-out;
    animation-iteration-count: infinite;
  }

  &__pagetop-image {
    display: block;
  }

  @keyframes floating {
    50% {
      -webkit-transform: translateY(pxToRem(30)) translateX(pxToRem(4));
              transform: translateY(pxToRem(30)) translateX(pxToRem(4));
    }
  }

  @-webkit-keyframes floating {
  50% {
      -webkit-transform: translateY(pxToRem(30)) translateX(pxToRem(4));
              transform: translateY(pxToRem(30)) translateX(pxToRem(4));
    }
  }

  &__comics-title {
    margin-bottom: 4%;
    @include fontSizePxToRem(12);
    text-align: center;
  }

  &__comics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6%;
  }

  &__comic {
    width: 32%;
  }

  &__copyright {
    @include fontSizePxToRem(8);
    text-align: center;
  }
}