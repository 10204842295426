.message {
  background-color: #ffeb8a;

  &__container {
    position: relative;
    margin-bottom: -4%;
    padding-top: 32%;
    padding-right: 5%;
    padding-left: 5%;

    @include mq() {
      margin-bottom: 0;
      padding-top: 25%;
      padding-bottom: 5%;
    }
  }

  &__xmas-image {
    display: block;
    width: 50%;
    position: absolute;
    top: -3%;
    left: -20%;

    @include mq() {
      display: none;
    }
  }

  &__title {
    width: 31%;
    position: absolute;
    top: 5%;
    right: 8%;

    @include mq() {
      top: 1%;
    }
  }

  &__title-image {
    display: block;
  }

  &__text {
    @include fontSizePxToRem(13);
    line-height: 1.6;
    letter-spacing: pxToRem(-1);
  }

  &__text + .message__text {
    margin-top: 7%;
  }

  &__bg-arrow {
    @extend .bg__arrow;
    border-top: pxToRem(60) solid #ffeb8a;
    background-color: #ffffff;
  }
}