.specification {
  background-color: #ffeb8a;

  &__container {
    position: relative;
    margin-bottom: -4%;
    padding-top: 15%;
    padding-right: 7%;
    padding-left: 5%;

    @include mq() {
      margin-bottom: 0;
      padding-top: 9%;
    }
  }

  &__title {
    width: 32%;
    position: absolute;
    top: 5%;
    left: 5%;

    @include mq() {
      top: -4%;
    }
  }

  &__title-image {
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__content-image {
    display: block;
    width: 39%;
    margin-right: auto;
    transform: translateY(12%);
  }

  &__info {
    @include fontSizePxToRem(9);
    line-height: 1.5;
    letter-spacing: pxToRem(-1);
  }

  .info {
    &__title {
      @include fontSizePxToRem(10);
      margin-bottom: 6%;
    }

    &__text {
      margin-bottom: 6%;
      transform: translateX(1%);
    }

    &__delivery {
      margin-bottom: 6%;
    }

    &__colon {
      padding-right: 2%;
      padding-left: 2%;
    }
  }

  &__bg-arrow {
    @extend .bg__arrow;
    border-top: pxToRem(60) solid #ffeb8a;
    background-color: #ffffff;
  }
}