$breakpoint-down: (
  'sm': 'screen and (max-width: 374px)',
  'md': 'screen and (max-width: 735px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)'
) !default;

// break point
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

// font size px to rem
@mixin fontSizePxToRem($size) {
  font-size: $size + px;
  font-size: ($size / 16) + rem;
}